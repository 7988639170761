<template>
  <div class="overlay-mobile" :class="displayDrawerMenu ? '' : 'custom-hidden'" @click="SET_MENU_DRAWER()">
    <section class="profile-sidebar">
      <div v-if="isLoggedIn" class="title">
        <span class="icon-user">
          <img src="/icons/icn-user.png" alt="user" />
        </span>
        <span class="username">{{ user.name }}</span>
      </div>
      <div v-else class="new-user-options">
        <a class="btn btn-login" @click="onClickLogin">
          <span>
            Iniciar Sesión
          </span>
          <span>
            <BIcon icon="account-outline" size="is-medium"> </BIcon>
          </span>
        </a>
        <a class="btn btn-create-account" @click="onClickRegister">
          Crear cuenta Premium
          <div>
            <div>$ <span class="amount">99</span> al mes</div>
          </div>
        </a>
      </div>
      <div class="items">
        <template v-if="showNav">
          <div v-for="(itemNav, index) in itemsNav" :key="itemNav.go" class="item" @click="goTo(itemNav)">
            <span class="icon">
              <img :src="itemNav.icon" :alt="itemNav.title" />
            </span>
            <span :class="selected === index ? 'selected' : ''">{{ itemNav.title }}</span>
          </div>
        </template>
        <template v-if="isLoggedIn">
          <template v-if="isB2CUser">
            <div v-for="(item, index) in items" :key="index" class="item" @click="goTo(item)">
              <span class="icon">
                <img :src="item.icon" :alt="item.title" />
              </span>
              <span :class="selected === index ? 'selected' : ''">{{ item.title }}</span>
            </div>
          </template>
          <div @click.prevent.stop="onClickLogout" class="item">
            <span class="icon">
              <img src="/icons/icn_sign_out.svg" alt="signout" />
            </span>
            <span>Cerrar Sesión</span>
          </div>
        </template>
      </div>
    </section>
    <Confirm
      question="¿Estás seguro que deseas cerrar tu sesión?"
      :showModalConfirm="showModalConfirm"
      @onConfirm="logout"
      @onCancel="closeModalConfirm"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import router from '@/router';
import Confirm from '@/components/Modals/Confirm';
import { ProfileSidebarGoTo, clickCrearCuentaPremium, clickLogin, clickBtnLogout } from '@/utils/analytics';
import { BIcon } from 'buefy/dist/esm/icon';

export default {
  name: 'ProfileSidebar',
  components: { Confirm, BIcon },
  data: () => ({
    items: [{ title: 'Ajustes', icon: '/icons/icn-ajustes.svg', to: 'payment-methods', event: 'click_btn_profile' }],
    itemsNav: [
      { title: 'Tips de GolStats', icon: '/icons/icn-tips-golstat.svg', to: 'tips', event: 'click_btn_tips_golstats' },
      { title: 'Partidos', icon: '/icons/icn-partidos.svg', to: 'matches', event: 'click_btn_partidos' },
      { title: 'Contáctanos', icon: '/icons/icn-contactanos.svg', to: 'contact', event: 'contactanos' },
    ],
    selected: null,
    showModalConfirm: false,
  }),
  computed: {
    ...mapGetters(['displayDrawerMenu']),
    ...mapGetters('loginGeneral', ['isB2CUser', 'isLoggedIn', 'getPremiumAccount', 'getIsGolstats']),
    ...mapState(['isSectionLoading', 'displayWidth']),
    ...mapState('loginGeneral', ['user', 'pymErr']),
    showNav() {
      return this.displayWidth < 1210;
    },
    section() {
      return this.$route.meta.title;
    },
  },
  methods: {
    ...mapMutations(['SET_MENU_DRAWER', 'setIsSectionLoading']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapActions('loginGeneral', ['doLogout']),
    ...mapActions('tips', ['fetchScenarios', 'fetchTournamentsWithScenarios']),
    closeModalConfirm() {
      this.showModalConfirm = false;
    },
    async logout() {
      this.setIsSectionLoading(true);
      try {
        await this.doLogout();
        if (this.$route.path.startsWith('/widget/')) {
          this.$router.go();
        } else {
          await this.fetchTournamentsWithScenarios(1);
        }
      } finally {
        this.setIsSectionLoading(false);
      }
    },
    goTo(route) {
      ProfileSidebarGoTo(route.event, this.getPremiumAccount, 'menu_lateral', this.getIsGolstats);
      if (route.to !== router.currentRoute.name) router.push({ name: route.to });
    },
    onClickRegister() {
      clickCrearCuentaPremium({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        section: 'menu_lateral',
        banner: 'Tips_Banner_Home',
      });
      this.SET_AUTH_COMPONENT('SelectPlan');
    },
    onClickLogin() {
      clickLogin({ is_premium: this.getPremiumAccount, section: 'menu_lateral', golstats: this.getIsGolstats });
      this.SET_AUTH_COMPONENT('Login');
    },
    onClickLogout() {
      clickBtnLogout(this.getPremiumAccount, this.getIsGolstats);
      this.showModalConfirm = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-sidebar {
  width: 278px;
  font-family: Circular-Std-Medium;
  border-right: solid 1px #e0e0e0;
  display: flex;
  flex-flow: column;
  transition: width ease 0.5s;
  background-color: #fff;
  justify-content: flex-start;
  .username {
    font-size: 2rem;
    min-width: 160px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon-user {
    margin-right: 7px;
    width: 30px;
  }
  .title {
    font-family: Circular-Std-Bold;
    font-size: 30px;
    letter-spacing: -0.17px;
    color: #494a4b;
    height: 140px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .items {
    display: flex;
    flex-flow: column;
    .item {
      cursor: pointer;
      padding: 15px 10px;
      margin: 0 40px;
      font-size: 18px;
      letter-spacing: -0.1px;
      color: #494a4b;
      text-align: left;
      display: flex;
      align-items: center;
      border-bottom: solid 1px #e0e0e0;
      .selected {
        font-family: Circular-Std-Bold;
      }
      .icon {
        margin-right: 7px;

        .selected-icon {
          color: #34a7ff;
        }
      }
    }
  }
}
@media screen and (max-width: 1209px) {
  .overlay-mobile {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    cursor: pointer;
    height: 100%;
    z-index: 10;
    display: flex;
    opacity: 1;
    justify-content: flex-end;
    transition: opacity ease 0.5s;
  }
  .custom-hidden {
    opacity: 0;
    z-index: -10;
    transition: opacity ease 0.5s;

    .profile-sidebar {
      width: 0;
      transition: width ease 0.3s;
    }
  }
  // .profile-sidebar .items .item:first-child {
  //   border-top: solid 1px #e0e0e0;
  // }
  .title {
    height: 140px;
    border-bottom: solid 1px #e0e0e0;
    padding-bottom: 35px;
  }
}
.profile-sidebar .items .item:hover {
  background-color: #dbdbdb;
}

.new-user-options {
  padding: 10px 30px;
}

a.btn {
  display: block;
  width: 100%;
  margin: 12px 0;
  padding: 10px 0;
  color: #3c3c3c;
  border: solid 2px #3c3c3c;
  border-radius: 28px;

  &.btn-login {
    position: relative;
    & span:first-child {
      margin-right: 20px;
    }
    & span:last-child {
      position: absolute;
      top: 2px;
      right: 5px;
    }
  }
}

.btn-create-account {
  position: relative;
  & > div {
    padding: 0.2rem 0.8rem 0;
    background-image: linear-gradient(to right, #ae0b0b, #e02020);
    position: absolute;
    transform: skew(-25deg);
    bottom: -14px;
    right: 60px;
    box-shadow: 2px 2px 3px black;

    & > div {
      color: white;
      font-size: 12px;
      font-family: 'Avenir-Medium' !important;
      transform: skew(25deg);
      & > .amount {
        font-family: 'Avenir-Pro-Bold' !important;
        font-weight: bold !important;
      }
    }
  }
}
</style>
